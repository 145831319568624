import { defineComponent } from 'vue';
import Page from '@/components/share/Page.vue';
import LanguageCells from '@/components/mobile/LanguageCells.vue';
export default defineComponent({
  name: 'LanguageFilter',
  components: {
    Page: Page,
    LanguageCells: LanguageCells
  },
  props: ['hideFilter'],
  inject: ['t']
});