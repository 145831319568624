import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_FubonLogo = _resolveComponent("FubonLogo");

  var _component_TSBLogo = _resolveComponent("TSBLogo");

  var _component_VIPCARLogo = _resolveComponent("VIPCARLogo");

  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createVNode(_component_FubonLogo, null, null, 512), [[_vShow, _ctx.isInFubon]]), _withDirectives(_createVNode(_component_TSBLogo, null, null, 512), [[_vShow, _ctx.isInTSB]]), _withDirectives(_createVNode(_component_VIPCARLogo, null, null, 512), [[_vShow, _ctx.isInVipcar]]), _withDirectives(_createVNode(_component_router_link, {
    to: _ctx.businessType === 1 ? "/".concat(_ctx.locale, "/") : "/".concat(_ctx.locale, "/travel/quarantine")
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_svg_icon, {
        class: "logo",
        iconName: "logo"
      })];
    }),
    _: 1
  }, 8, ["to"]), [[_vShow, !_ctx.isInTSB && !_ctx.isInVipcar && !_ctx.isInFubon]])], 64);
}