export const getInfoUrl = (t: any) => [
  {
    name: t('homepage.prevention_news'),
    image: '/images/image_quicknews',
    icon: 'megaphone',
    link: 'https://www.tourbobo.com/quarantine/news/'
  },
  {
    name: t('homepage.prevention_grants'),
    image: '/images/image_subsidy',
    icon: 'money_bag',
    link: 'https://www.tourbobo.com/quarantine/news/%E6%9C%89%E5%93%AA%E4%BA%9B%E9%98%B2%E7%96%AB%E8%A3%9C%E5%8A%A9%EF%BC%9F%E5%8F%88%E8%A9%B2%E5%A6%82%E4%BD%95%E7%94%B3%E8%AB%8B%EF%BC%9F%E9%98%B2%E7%96%AB%E8%A3%9C%E5%8A%A9%E5%AE%8C%E6%95%B4%E6%8C%87/'
  },
  {
    name: t('homepage.prevention_fa_qs'),
    image: '/images/image_faq',
    icon: 'faq',
    link: 'https://www.tourbobo.com/quarantine/quarnantine-hotel-faq/'
  }
]

export const getTravelInfoUrl = (t: any) => [
  {
    name: t('homepage.spot'),
    image: 'spot',
    link: 'https://travel.tourbobo.com/post?category=%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF'
  },
  {
    name: t('homepage.discount'),
    image: 'discount',
    link: 'https://travel.tourbobo.com/post?category=%E5%84%AA%E6%83%A0%E8%B3%87%E8%A8%8A'
  },
  {
    name: t('homepage.topic'),
    image: 'topic',
    link: 'https://travel.tourbobo.com/hotel-category'
  },
  {
    name: t('homepage.popular'),
    image: 'popular',
    link: 'https://travel.tourbobo.com/post?category=%E6%9C%80%E6%96%B0%E6%B6%88%E6%81%AF&post_id=oOZwFAvj3VXMOUkugMTkk'
  }
]
