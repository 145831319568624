import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, computed, inject } from 'vue';
import { useStore } from 'vuex';
import { ALL_LANGUAGES } from '@/constants';
import { useRouter } from 'vue-router';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: ['handleClosePopup'],
  setup: function setup(props) {
    var store = useStore();
    var router = useRouter();
    var locale = inject('locale');
    var countryCode = computed(function () {
      return localStorage.getItem('locale');
    });
    var languageList = computed(function () {
      return ALL_LANGUAGES;
    });
    var currentIcon = computed(function () {
      var _languageList$value$f;

      return ((_languageList$value$f = languageList.value.find(function (country) {
        return country.countryCode === countryCode.value;
      })) === null || _languageList$value$f === void 0 ? void 0 : _languageList$value$f.icon) || 'flag-for-taiwan';
    });

    var updateCountryCode = function updateCountryCode(countryCode) {
      store.dispatch('options/setCountryCode', countryCode);
      locale.value = countryCode;
      props.handleClosePopup && props.handleClosePopup();
      router.push({
        name: router.currentRoute.value.name,
        params: {
          locale: countryCode
        },
        query: router.currentRoute.value.query
      });
    };

    return {
      languageList: languageList,
      currentIcon: currentIcon,
      updateCountryCode: updateCountryCode
    };
  }
});