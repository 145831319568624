import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent, ref, computed, inject } from 'vue';
import { useStore } from 'vuex';
import LanguageCells from '@/components/mobile/LanguageCells.vue';
import { ALL_LANGUAGES } from '@/constants';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, "LanguageCells", LanguageCells), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  setup: function setup() {
    var store = useStore();
    var showPopover = ref(false);
    var locale = inject('locale');
    var currentIcon = computed(function () {
      var _ALL_LANGUAGES$find;

      return ((_ALL_LANGUAGES$find = ALL_LANGUAGES.find(function (country) {
        return country.countryCode === locale.value;
      })) === null || _ALL_LANGUAGES$find === void 0 ? void 0 : _ALL_LANGUAGES$find.icon) || 'flag-for-taiwan';
    });

    var onPopoverOpen = function onPopoverOpen(e) {
      e.stopPropagation();
      showPopover.value = true;
    };

    var onClickOverlay = function onClickOverlay() {
      showPopover.value = false;
    };

    return {
      showPopover: showPopover,
      onPopoverOpen: onPopoverOpen,
      onClickOverlay: onClickOverlay,
      currentIcon: currentIcon
    };
  }
});