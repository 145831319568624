import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { computed, defineComponent, inject, ref } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Popup from '@/components/share/Popup.vue';
import NavBar from '@/components/share/NavBar.vue';
import Logo from '@/components/navbar/Logo.vue';
import { getInfoUrl } from '@/utils/infoUrl';
import LanguageFilter from '@/components/mobile/LanguageFilter.vue';
import { ALL_LANGUAGES } from '@/constants';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon,
    Popup: Popup,
    NavBar: NavBar,
    Logo: Logo,
    LanguageFilter: LanguageFilter
  },
  props: ['openCheckOrder', 'logout', 'onLoginModelToggle'],
  setup: function setup() {
    var store = useStore();
    var showSideMenu = ref(false);
    var isLanguageModalOpen = ref(false);
    var t = inject('t');
    var locale = inject('locale');
    var vueAppUrl = process.env.VUE_APP_URL;
    var info = computed(function () {
      return getInfoUrl(t);
    });
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var countryCode = computed(function () {
      return store.state.options.countryCode;
    });
    var userInfo = useAuthStore().userInfo;
    var isInEvent = computed(function () {
      return router.currentRoute.value.name === 'TSB' || router.currentRoute.value.name === 'TSBTRAVEL';
    });
    var isLoggedIn = useAuthStore().isLoggedIn;
    var currentLanguage = computed(function () {
      return ALL_LANGUAGES.find(function (country) {
        return country.countryCode === countryCode.value;
      });
    });

    var toggleSlide = function toggleSlide() {
      if (showSideMenu.value) {
        showSideMenu.value = false;
      } else {
        showSideMenu.value = true;
      }
    };

    var toggleLanguageModal = function toggleLanguageModal() {
      if (isLanguageModalOpen.value) {
        isLanguageModalOpen.value = false;
      } else {
        isLanguageModalOpen.value = true;
      }
    };

    var isInHotel = computed(function () {
      return router.currentRoute.value.name === 'Hotel';
    });

    var goBack = function goBack() {
      router.back();
    };

    var handleRedirect = function handleRedirect() {
      if (!isInEvent.value) {
        if (businessType.value === 1) {
          window.location.href = "/".concat(locale.value, "/travel/quarantine");
        } else {
          window.location.href = "/".concat(locale.value, "/");
        }
      } else {
        if (businessType.value === 1) {
          window.location.href = "/".concat(locale.value, "/event/TSB");
        } else {
          window.location.href = "/".concat(locale.value, "/event/TSBTRAVEL");
        }
      }
    };

    var redirectTo = function redirectTo(path) {
      if (path) {
        window.location.href = path;
      }
    };

    var sendMail = function sendMail() {
      window.location.href = 'mailto:reservation.service@dunqian.com';
    };

    return {
      vueAppUrl: vueAppUrl,
      t: t,
      isLoggedIn: isLoggedIn,
      businessType: businessType,
      showSideMenu: showSideMenu,
      isLanguageModalOpen: isLanguageModalOpen,
      toggleSlide: toggleSlide,
      toggleLanguageModal: toggleLanguageModal,
      goBack: goBack,
      isInHotel: isInHotel,
      info: info,
      currentLanguage: currentLanguage,
      handleRedirect: handleRedirect,
      userInfo: userInfo,
      redirectTo: redirectTo,
      sendMail: sendMail
    };
  }
});