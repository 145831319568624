import { defineComponent, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var locale = inject('locale');
    return {
      locale: locale
    };
  }
});