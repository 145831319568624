import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_language_cells = _resolveComponent("language-cells");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: "nav-link",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.onPopoverOpen && _ctx.onPopoverOpen.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_svg_icon, {
    class: "country-icon",
    iconName: _ctx.currentIcon
  }, null, 8, ["iconName"])]), _createVNode(_component_van_popover, {
    show: _ctx.showPopover,
    offset: [-50, 30],
    overlay: "",
    onClickOverlay: _ctx.onClickOverlay,
    "overlay-class": "city-overlay"
  }, {
    reference: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_language_cells)];
    }),
    _: 3
  }, 8, ["show", "onClickOverlay"])], 64);
}