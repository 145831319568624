import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { computed, defineComponent, inject } from 'vue';
import PcNavLink from '@/components/navbar/PcNavLink.vue';
import PcNavCurrency from '@/components/navbar/PcNavCurrency.vue';
import PcNavCountry from '@/components/navbar/PcNavCountry.vue';
import PcNavRegister from '@/components/navbar/PcNavRegister.vue';
import PcNavPersonal from '@/components/navbar/PcNavPersonal.vue';
import Logo from '@/components/navbar/Logo.vue';
import { useStore } from 'vuex';
import { useAuthStore } from '@/hook/useAuthStore';
export default defineComponent({
  components: {
    PcNavLink: PcNavLink,
    PcNavCurrency: PcNavCurrency,
    PcNavCountry: PcNavCountry,
    PcNavRegister: PcNavRegister,
    PcNavPersonal: PcNavPersonal,
    Logo: Logo
  },
  props: ['openCheckOrder', 'logout', 'onLoginModelToggle'],
  setup: function setup() {
    var store = useStore();
    var t = inject('t');
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var userInfo = useAuthStore().userInfo;
    var isLoggedIn = useAuthStore().isLoggedIn;

    var handleLanding = function handleLanding() {
      window.location.href = 'https://travel.tourbobo.com/home';
    };

    return {
      t: t,
      isLoggedIn: isLoggedIn,
      businessType: businessType,
      handleLanding: handleLanding,
      userInfo: userInfo
    };
  }
});