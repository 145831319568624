import { HotelOptionListRes } from '@/store/types/hotels'
import { computed, onMounted, Ref } from 'vue'
import { useStore } from 'vuex'
export interface useHotelOptionType {
  hotelOptionData: Ref<HotelOptionListRes>
}
export const useHotelOption = (): useHotelOptionType => {
  const store = useStore()
  const hotelOptionData: Ref<HotelOptionListRes> = computed(() => store.state.hotels.hotelOptionList)
  return { hotelOptionData }
}
