import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";

var _components;

import "core-js/modules/es.function.name.js";
import { defineComponent, ref, computed } from 'vue';
export default defineComponent({
  components: (_components = {}, _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _components),
  setup: function setup() {
    var showPopover = ref(false);
    var currency = computed(function () {
      return ['TWD'];
    });

    var onPopoverOpen = function onPopoverOpen(e) {
      e.stopPropagation();
      showPopover.value = true;
    };

    var onClickOverlay = function onClickOverlay() {
      showPopover.value = false;
    };

    return {
      showPopover: showPopover,
      onPopoverOpen: onPopoverOpen,
      onClickOverlay: onClickOverlay,
      currency: currency
    };
  }
});