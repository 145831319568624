import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject } from 'vue';
import router from '@/router';
import SvgIcon from '@/components/share/SvgIcon.vue';
import TSBLogo from '@/components/event/TSB/Logo.vue';
import VIPCARLogo from '@/components/event/VIPCAR/Logo.vue';
import FubonLogo from '@/components/event/fubon/Logo.vue';
import { useStore } from 'vuex';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon,
    TSBLogo: TSBLogo,
    VIPCARLogo: VIPCARLogo,
    FubonLogo: FubonLogo
  },
  setup: function setup() {
    var locale = inject('locale');
    var store = useStore();
    var businessType = computed(function () {
      return store.state.form.search.business_type;
    });
    var isInTSB = computed(function () {
      return router.currentRoute.value.name === 'TSB' || router.currentRoute.value.name === 'TSBTRAVEL';
    });
    var isInVipcar = computed(function () {
      return router.currentRoute.value.name === 'Vipcar';
    });
    var isInFubon = computed(function () {
      return router.currentRoute.value.name === 'Fubon';
    });
    return {
      businessType: businessType,
      isInTSB: isInTSB,
      isInVipcar: isInVipcar,
      isInFubon: isInFubon,
      locale: locale
    };
  }
});