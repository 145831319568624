import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-34726315"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["onClick"];
var _hoisted_2 = {
  class: "name"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  return _openBlock(), _createBlock(_component_van_cell_group, null, {
    default: _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageList, function (data, index) {
        return _openBlock(), _createBlock(_component_van_cell, {
          class: "custom-van-cell",
          key: index
        }, {
          default: _withCtx(function () {
            return [_createElementVNode("div", {
              class: "link",
              onClick: function onClick() {
                return _ctx.updateCountryCode(data.countryCode);
              }
            }, [_createVNode(_component_svg_icon, {
              class: "country-icon",
              iconName: data.icon
            }, null, 8, ["iconName"]), _createElementVNode("span", _hoisted_2, _toDisplayString(data.name), 1)], 8, _hoisted_1)];
          }),
          _: 2
        }, 1024);
      }), 128))];
    }),
    _: 1
  });
}