import "core-js/modules/es.function.name.js";
import { computed, defineComponent, inject } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
import router from '@/router';
export default defineComponent({
  components: {
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var locale = inject('locale');
    var isInEventTravel = computed(function () {
      return router.currentRoute.value.name === 'TSBTRAVEL';
    });
    return {
      isInEventTravel: isInEventTravel,
      locale: locale
    };
  }
});