import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-842d6cbc"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "link"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
    class: "nav-link",
    onClick: _cache[0] || (_cache[0] = function () {
      return _ctx.onPopoverOpen && _ctx.onPopoverOpen.apply(_ctx, arguments);
    })
  }, "TWD"), _createVNode(_component_van_popover, {
    show: _ctx.showPopover,
    offset: [-50, 30],
    overlay: "",
    onClickOverlay: _ctx.onClickOverlay,
    "overlay-class": "city-overlay"
  }, {
    reference: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_van_cell_group, null, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currency, function (data, index) {
            return _openBlock(), _createBlock(_component_van_cell, {
              class: "custom-van-cell",
              key: index
            }, {
              default: _withCtx(function () {
                return [_createElementVNode("div", _hoisted_1, _toDisplayString(data), 1)];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      })];
    }),
    _: 3
  }, 8, ["show", "onClickOverlay"])], 64);
}