import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-6bc1554d"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "icon-frame"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createBlock(_component_router_link, {
    to: "/".concat(_ctx.locale, "/event/fubon")
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
        class: "fubon-logo",
        iconName: "fubon-logo"
      }), _createVNode(_component_svg_icon, {
        class: "cross",
        iconName: "icon-24-contact"
      }), _createVNode(_component_svg_icon, {
        class: "logo",
        iconName: "logo"
      })])];
    }),
    _: 1
  }, 8, ["to"]);
}