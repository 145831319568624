import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_language_cells = _resolveComponent("language-cells");

  var _component_page = _resolveComponent("page");

  return _openBlock(), _createBlock(_component_page, {
    class: "language-filter",
    onClose: _ctx.hideFilter,
    title: _ctx.t('homepage.please_pick_your_language')
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_language_cells, {
        handleClosePopup: _ctx.hideFilter
      }, null, 8, ["handleClosePopup"])];
    }),
    _: 1
  }, 8, ["onClose", "title"]);
}